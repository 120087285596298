import React, { useEffect } from 'react';

const Header = ({ handleNav, navOpen, menuOpen }) => {


    return (
        <>
            {/* <div className="" onClick={handleNav}>
                <div className={`fixed-nav ${navOpen ? "nav-open" : ""}`}>
                    <div className={`text-nav ${navOpen ? "text-nav-open" : ""}`}>
                        menu
                    </div>
                    <div
                        className={`menu-container ${menuOpen ? "menu-open" : ""}`}
                    >
                        <div className="navigation">
                            <div className="navigation__name heading-1"></div>
                            <div className="close-nav" onClick={handleNav}>
                                <svg
                                    className="close-nav"
                                    version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    width="50px"
                                    height="50px"
                                    viewBox="0 0 122.881 122.88"
                                    enableBackground="new 0 0 122.881 122.88"
                                    xmlSpace="preserve"
                                >
                                    <g>
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M61.44,0c33.933,0,61.441,27.507,61.441,61.439 c0,33.933-27.508,61.44-61.441,61.44C27.508,122.88,0,95.372,0,61.439C0,27.507,27.508,0,61.44,0L61.44,0z M81.719,36.226 c1.363-1.363,3.572-1.363,4.936,0c1.363,1.363,1.363,3.573,0,4.936L66.375,61.439l20.279,20.278c1.363,1.363,1.363,3.573,0,4.937 c-1.363,1.362-3.572,1.362-4.936,0L61.44,66.376L41.162,86.654c-1.362,1.362-3.573,1.362-4.936,0c-1.363-1.363-1.363-3.573,0-4.937 l20.278-20.278L36.226,41.162c-1.363-1.363-1.363-3.573,0-4.936c1.363-1.363,3.573-1.363,4.936,0L61.44,56.504L81.719,36.226 L81.719,36.226z"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </div>

                        <div className="navigation__menu">
                            <div className="nav-wrapper">
                                <div className="navigation__item ">
                                    <a href="#home" className="navigation__link">
                                        <sup>01</sup> Home
                                    </a>
                                </div>

                                <div className="navigation__item--1">
                                    {" "}
                                    <a href="#home" className="navigation__link">
                                        <sup>01</sup> Home
                                    </a>
                                </div>
                            </div>
                            <div className="nav-wrapper">
                                <div className="navigation__item ">
                                    {" "}
                                    <a href="#about-me" className="navigation__link">
                                        <sup>02</sup> about me{" "}
                                    </a>
                                </div>
                                <div className="navigation__item--2">
                                    <a href="#about-me" className="navigation__link">
                                        <sup>02</sup> about me
                                    </a>
                                </div>
                            </div>
                            <div className="nav-wrapper">
                                <div className="navigation__item ">
                                    <a href="#projects" className="navigation__link">
                                        <sup>03</sup> Projects{" "}
                                    </a>
                                </div>
                                <div className="navigation__item--3">
                                    <a href="#projects" className="navigation__link">
                                        <sup>03</sup> Projects
                                    </a>
                                </div>
                            </div>{" "}
                            <div className="nav-wrapper">
                                <div className="navigation__item ">
                                    {" "}
                                    <a href="#degrees" className="navigation__link">
                                        <sup>04</sup> Certificates
                                    </a>
                                </div>
                                <div className="navigation__item--4">
                                    {" "}
                                    <a href="#degrees" className="navigation__link">
                                        <sup>04</sup> Certificates
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="socials">
                            <div className="socials__item--1">
                                {" "}
                                <a
                                    alt="gihub icon"
                                    href="https://github.com/jcharo1"
                                    className="navigation__link"
                                >
                                    github
                                </a>
                            </div>
                            <div className="socials__item--2">
                                <a
                                    alt="linkedin icon"
                                    href="https://www.linkedin.com/in/justincharo/"
                                    className="navigation__link"
                                >
                                    linkedin
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div

                className="heading-1 header__title"
            >

            </div>
            <div

                className="heading-1 header__sub-title"
            >
                {/* */}
            </div>
            <div className="header__contact">
                <div className="get-in-touch">
                    {" "}
                    <a
                        alt="linkedin icon"
                        href="https://www.linkedin.com/in/justincharo/"
                        className="navigation__link"
                    >
                        <div className=" get-in-touch__linkedin ">
                            <div className="get-in-touch__linkedin__text">
                                <svg
                                    viewBox="0 0 300 300"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                >
                                    <path
                                        id="SunCatcherStudio"
                                        fill="none"
                                        stroke="none"
                                        d="M 32.550491,148.48008 A -108.15144,-108.15144 0 0 1 140.70194,40.328644 -108.15144,-108.15144 0 0 1 248.85338,148.48008 -108.15144,-108.15144 0 0 1 140.70194,256.63153 -108.15144,-108.15144 0 0 1 32.550491,148.48008 Z"
                                    />
                                    <text
                                        fontSize="40"
                                        //   fill="#000000"
                                        letterSpacing="6"
                                        fontFamily="bebas_neueregular"
                                        fontWeight="bold"
                                    >
                                        <textPath
                                            xlinkHref="#SunCatcherStudio"
                                            side="left"
                                            startOffset="5"
                                        >
                                            Linkedin
                                        </textPath>
                                    </text>
                                </svg>
                            </div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 30 30"
                                width="96px"
                                height="96px"
                            >
                                {" "}
                                <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M10.496,8.403 c0.842,0,1.403,0.561,1.403,1.309c0,0.748-0.561,1.309-1.496,1.309C9.561,11.022,9,10.46,9,9.712C9,8.964,9.561,8.403,10.496,8.403z M12,20H9v-8h3V20z M22,20h-2.824v-4.372c0-1.209-0.753-1.488-1.035-1.488s-1.224,0.186-1.224,1.488c0,0.186,0,4.372,0,4.372H14v-8 h2.918v1.116C17.294,12.465,18.047,12,19.459,12C20.871,12,22,13.116,22,15.628V20z" />
                            </svg>
                        </div>{" "}
                    </a>
                    <a
                        alt="github icon"
                        href="https://github.com/jcharo1"
                        className="navigation__link"
                    >
                        <div className="get-in-touch__github">
                            <div className="get-in-touch__github__text">
                                <svg
                                    viewBox="0 0 300 300"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                >
                                    <path
                                        id="SunCatcherStudio1"
                                        fill="none"
                                        stroke="none"
                                        d="M 32.550491,148.48008 A -108.15144,-108.15144 0 0 1 140.70194,40.328644 -108.15144,-108.15144 0 0 1 248.85338,148.48008 -108.15144,-108.15144 0 0 1 140.70194,256.63153 -108.15144,-108.15144 0 0 1 32.550491,148.48008 Z"
                                    />
                                    <text
                                        fontSize="40"
                                        //   fill="#000000"
                                        letterSpacing="6"
                                        fontFamily="bebas_neueregular"
                                        fontWeight="bold"
                                    >
                                        <textPath
                                            xlinkHref="#SunCatcherStudio1"
                                            side="left"
                                            startOffset="5"
                                        >
                                            Github
                                        </textPath>
                                    </text>
                                </svg>
                            </div>
                            <svg
                                width="82"
                                height="82"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 100 100"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z"
                                />
                            </svg>
                        </div>
                    </a>
                    <a
                        alt="email icon"
                        href="mailto: justinrcharo@gmail.com"
                        className="navigation__link"
                    >
                        <div
                            duration={1.7}
                            animateIn="fadeIn"
                            animateOnce
                            className="get-in-touch__email"
                        >
                            <div className="get-in-touch__email__text">
                                <svg
                                    viewBox="0 0 300 300"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                >
                                    <path
                                        id="SunCatcherStudio"
                                        fill="none"
                                        stroke="none"
                                        d="M 32.550491,148.48008 A -108.15144,-108.15144 0 0 1 140.70194,40.328644 -108.15144,-108.15144 0 0 1 248.85338,148.48008 -108.15144,-108.15144 0 0 1 140.70194,256.63153 -108.15144,-108.15144 0 0 1 32.550491,148.48008 Z"
                                    />
                                    <text
                                        fontSize="40"
                                        //   fill="#000000"
                                        letterSpacing="6"
                                        fontFamily="bebas_neueregular"
                                        fontWeight="bold"
                                    >
                                        <textPath
                                            xlinkHref="#SunCatcherStudio"
                                            side="left"
                                            startOffset="5"
                                        >
                                            Email
                                        </textPath>
                                    </text>
                                </svg>
                            </div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                id="mdi-send-circle"
                                width="96px"
                                height="96px"
                                viewBox="0 0 25 25"
                            >
                                <path d="M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M8,7.71V11.05L15.14,12L8,12.95V16.29L18,12L8,7.71Z" />
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
        </>
    );
}

export default Header;