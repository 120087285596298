import pokemonBinder from "./img/pokemon.png";
import pokemonBinderWebp from "./img/pokemon.webp";
import fyyur from "./img/Fyyur.png";
import fyyurWebp from "./img/Fyyur.webp";
import trivia from "./img/Trivia.png";
import triviaWebp from "./img/Trivia.webp";
import likey from "./img/Likey.png";
import likeyWebp from "./img/Likey.webp";
// import { gsap } from "gsap";
import { gsap } from "./gsap";
import saiz from "./img/saiz.png";
import { useToast } from "react-toastified";
import saizWebp from "./img/saiz.webp";
// import Navigation from "./Navigation.js";
import Knowledge from "./Knowledge";
import ProjectTrio from "./ProjectTrio";
import { Graph } from "react-github-activity-calendar";
import "./sass/_main.scss";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { SplitText } from "gsap/SplitText";
import Header from "./Header";
import Experiance from "./Experiance";
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import HoverComponent from "./hover";
// import TryMeArrow from "./TryMe";
// const githubApiKey = process.env.REACT_APP_API_KEY;
// gsap.registerPlugin(Flip);
gsap.registerPlugin(SplitText);

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
const bgOptions = [
  { label: "Deep Navy Blue", value: "#273D52" },
  { label: "Muted Olive", value: "#A8A878" },
  { label: "Dark Purple Grey", value: "#474056" },
  { label: "Bright Coral", value: "#FF6B6B" },
  { label: "Ocean Teal", value: "#2E8B8B" },
  { label: "Warm Pumpkin", value: "#FF851B" },
  { label: "Moss Green", value: "#4B7940" },
  { label: "Dusky Rose", value: "#D0848E" },
  { label: "Earth Brown", value: "#8B4513" },
  { label: "Sapphire Blue", value: "#0F52BA" },
  { label: "Wine Red", value: "#722F37" },
  { label: "Golden Sun", value: "#FFC30B" },
];

const textOptions = [
  { label: "Soft Cream", value: "#F8EBB2" },
  { label: "Almost Black", value: "#2A2A2A" },
  { label: "Very Light Grey", value: "#EDEDED" },
  { label: "Charcoal Grey", value: "#36454F" },
  { label: "Taupe", value: "#8B8589" },
  { label: "Pale Lavender", value: "#ECE2F0" },
  { label: "Ash Grey", value: "#B2BEB5" },
  { label: "Creamy White", value: "#FFFFE0" },
  { label: "Pale Mint", value: "#98FF98" },
  { label: "Light Goldenrod", value: "#FAFAD2" },
  { label: "Powder Blue", value: "#B0E0E6" },
  { label: "Vanilla", value: "#F3E5AB" },
];

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [packageApiCall, setPackageApiCall] = useState({});
  const [packageToastApiCall, setToastPackageApiCall] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [colorMode, setColorMode] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const githubApiKey = "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";
  const githubUserName = "jcharo1"; // <github-username>

  const boxRef = useRef(null);
  const [isHslColor, setIsHslColor] = useState(false);
  const [bgColorValue, setBgColorValue] = useState("");
  const [textColorValue, setTextColorValue] = useState("");

  const handleResetColors = () => {
    setBgColorValue("");
    setTextColorValue("");
  };
  const handleBgColorChange = (event) => {
    setBgColorValue(event.target.value);
    setIsHslColor(false);
    console.log(event.target.value);
  };

  const handleTextColorChange = (event) => {
    setTextColorValue(event.target.value);
    setIsHslColor(false);
  };

  const bgColor = bgColorValue;
  const textColor = textColorValue;

  useEffect(() => {
    // window.onbeforeunload = function () {
    //   window.scrollTo(0, 0);
    // };
    const getDataDownloads = async () => {
      try {
        fetch(
          "https://api.npmjs.org/downloads/range/2023-02-28:2026-02-22/react-github-activity-calendar",
          {
            method: "GET",
            redirect: "follow",
          }
        )
          .then((response) => response.json())
          .then((result) => {
            // console.log(result.downloads);

            setPackageApiCall(result.downloads);
            setisLoading(true);
          });
      } catch (e) {
        console.log(e);
      }
    };

    getDataDownloads();
  }, []);
  const [toastDownloadTotal, setToastDownloadTotal] = useState(0);
  useEffect(() => {
    // window.onbeforeunload = function () {
    //   window.scrollTo(0, 0);
    // };
    const getDataToastDownloads = async () => {
      try {
        fetch(
          "https://api.npmjs.org/downloads/range/2023-02-28:2026-02-22/react-toastified",
          {
            method: "GET",
            redirect: "follow",
          }
        )
          .then((response) => response.json())
          .then((result) => {
            // console.log(result.downloads);
            let totalCount = 0;
            let totalPackageDownloads;

            totalPackageDownloads = result.downloads.map((data) => {
              totalCount += data.downloads;
            });
            setToastDownloadTotal(totalCount);
            setToastPackageApiCall(result.downloads);
            setisLoading(true);
          });
      } catch (e) {
        console.log(e);
      }
    };

    getDataToastDownloads();
  }, []);
  // ///overall mouse trailer
  const blob = document.getElementById("blob");
  document.documentElement.onpointermove = (event) => {
    const { pageX, pageY } = event;

    blob?.animate(
      {
        left: `${pageX}px`,
        top: `${pageY}px`,
      },
      { duration: 3000, fill: "forwards" }
    );
  };
  //menu mouse trailer

  let totalCount = 0;
  let totalPackageDownloads;
  if (isLoading) {
    totalPackageDownloads = packageApiCall.map((data) => {
      totalCount += data.downloads;
    });
  }

  const handleColorChange = (e) => {
    setColorMode(!colorMode);
    if (colorMode === false) {
      document.body.style.backgroundColor = "rgb(255, 246, 239)";
      document.body.style.color = "#201e1e";
    } else {
      document.body.style.backgroundColor = "#201e1e";
      document.body.style.color = "rgb(255, 246, 239)";
    }
  };

  const handleNav = () => {
    setMenuOpen(!menuOpen);
    setNavOpen(!navOpen);
  };
  const main = useRef();
  const root = useRef();
  const smoother = useRef();

  // useLayoutEffect(() => {
  //   const ctx = gsap.context(() => {}, root); // <- scopes all selector text

  //   return () => ctx.revert();
  // }, []);

  const aboutscroll = (e) => {
    smoother.current.scrollTo(".about-me", true, "top top");
  };
  const { addToast } = useToast();
  const [currentType, setCurrentType] = useState("info");

  const toastTypes = ["info", "success", "error", "warning"];

  const handleClick = () => {
    // Display the toast based on the current type
    addToast(`Hey there! This is a ${currentType} toast.`, {
      type: currentType,
      style: {
        color: "black",
        backgroundColor: "pink",
        padding: "24px",
        borderRadius: "8px",
      },
    });

    // Rotate to the next toast type for the next click
    const nextTypeIndex =
      (toastTypes.indexOf(currentType) + 1) % toastTypes.length;
    setCurrentType(toastTypes[nextTypeIndex]);
  };
  const initializeAnimations = () => {
    // Ensure that 'root.current' is not null
    if (!root.current) return;
    const titleTimeline = gsap.timeline();

    // Title Animation
    titleTimeline.to(".header__title", {
      duration: 2,
      text: {
        padSpace: true,
        value: "Full Stack",
        delimiter: "",
      },
      yoyoEase: true,
      ease: "power2",
    });

    // Sub-title Animation with delay
    titleTimeline.to(".header__sub-title", {
      text: {
        padSpace: true,
        value: "web developer",
        delimiter: "",
      },
      yoyoEase: true,
      delay: 1,
      // repeatDelay: 0.5,
    });

    // Initialize ScrollSmoother
    smoother.current = ScrollSmoother.create({
      content: "#scrollsmoother-container",
      smooth: 1,
      normalizeScroll: false,
      effects: true,
      // smoothTouch: 0.1,
      effects: true,
      preventDefault: true,
      ignoreMobileResize: true, // skips ScrollTrigger.refresh() on mobile resizes from address bar showing/hiding
    });

    // const split = new SplitText("p", { type: "lines" });
    // split.lines.forEach((target) => {
    //   gsap.to(target, {
    //     backgroundPositionX: 0,
    //     ease: "none",
    //     scrollTrigger: {
    //       trigger: target,
    //       scrub: 1,
    //       start: "top center",
    //       end: "bottom center",
    //     },
    //   });
    // });

    const headings = gsap.utils.toArray(".js-title").reverse();
    headings.forEach((heading, i) => {
      const headingIndex = i + 1;
      const mySplitText = new SplitText(heading, { type: "chars" });
      const chars = mySplitText.chars;

      chars.forEach((char, i) => {
        smoother.current.effects(char, {
          lag: (i - headingIndex) * 0.2,
          speed: 1,
        });
      });
    });
    let tl = gsap.timeline();
    let mySplitText = new SplitText("#split-stagger", {
      type: "words,chars",
    });
    let chars = mySplitText.chars;

    chars.forEach((char, i) => {
      smoother.effects(char, { speed: 1, lag: (i + 1) * 0.1 });
    });

    gsap.set(".headingtest", {
      yPercent: -150,
      opacity: 1,
    });
    // GSAP and ScrollSmoother logic here...
    // This is where you set up your animations and effects
  };

  useEffect(() => {
    const ctx = gsap.context(initializeAnimations, root);
    return () => ctx.revert();
  }, []);

  return (
    <>
      <div className="wrapper" ref={root}>
        {/* <div className="scroll-arrow" id="rect"></div> */}
        <div id="blob"></div>
        <div id="blur"></div>
        {/* <div className={` ${navOpen ? "nav-bg" : ""}`}></div> */}
        <div className="container " id="scrollsmoother-container">
          <div className={`header-top`}>
            <div className="header-top__name">
              <h1 class="js-title"> Justin Charo</h1>
              <h1 class="js-title"> Justin Charo</h1>
              <h1 class="js-title"> Justin Charo</h1>
            </div>
            <div className="mid"></div>
            <div className="header-top__menu">
              <div className="switch">
                <div className="switch__1">
                  <input id="switch-1" type="checkbox" />
                  <label htmlFor="switch-1" onClick={handleColorChange}></label>
                </div>
              </div>
            </div>
          </div>
          <div className="header" id="home">
            <div className="headingtest ">
              <p>Full Stack</p>

              <div
                class={`text-container ${
                  colorMode ? "darkmode-text-bg" : "regmode-text-bg"
                }`}
              >
                <p>Web Developer</p>
                <p data-speed="0.95">Web Developer</p>
                <p data-speed="0.9">Web Developer</p>
                <p data-speed="0.85">Web Developer</p>
                <p data-speed="0.8">Web Developer</p>
                <p data-speed="0.75">Web Developer</p>
                <p data-speed="0.7">Web Developer</p>
                {/* <p>About Me</p>
                  <p data-speed="auto" data-lag=".4">
                    About Me
                  </p>
                  <p data-speed="auto" data-lag=".3">
                    About Me
                  </p>
                  <p data-speed="auto" data-lag=".2">
                    About Me
                  </p>{" "}
                  <p data-speed="auto" data-lag=".1">
                    About Me
                  </p>
                  <p data-speed="auto" data-lag="0.2">
                    About Me */}
                {/* <p data-speed="1" data-lag="0.9">
                    About Me
                  </p>
                  <p data-speed="1" data-lag="0.85">
                    About Me
                  </p>
                  <p data-speed="1" data-lag="0.8">
                    About Me
                  </p>
                  <p data-speed="1" data-lag="0.75">
                    About Me
                  </p>
                  <p data-speed="1" data-lag="0.7">
                    About Me
                  </p> */}
              </div>
            </div>
            {/* <Header /> */}
          </div>
          {/* <button onClick={handleClick}>Show Toast</button>{" "} */}
          <div
            className="about-me bg-gray-200"
            id="about-me"
            data-lag="0.1"
            data-speed="1"
          >
            <Experiance colorMode={colorMode} />
            {/* <div
              // animateOut="bounceOutRight"
              className="about-me__header"
            >
              <span>about me</span>
            </div> */}

            {/* <div
              className={`about-me__text ${
                colorMode ? "text-darkmode" : "text"
              }`}
            >
              <p>
                As a software developer with 2+ years of experience, I am
                passionate about creating solutions that streamline processes
                and solve problems. I have hands-on experience as a freelancer
                building responsive websites. My drive to continuously improve
                has led me to complete numerous Udacity courses and expand my
                skill set in technologies including React, Python, Javascript,
                HTML, CSS/Sass/scss, Java, Flask, SQLAlchemy, PostgreSQL,
                Docker, and Auth0. I am highly motivated and committed to using
                my skills to drive success for companies while continuously
                expanding my expertise .
              </p>
            </div> */}
          </div>
          <div className="projects" id="projects">
            <div className="projects__header">
              <span>Recent Projects</span>
            </div>
            <div className="npm-project">
              <div
                className={` ${
                  colorMode
                    ? "npm-project__content"
                    : "npm-project__content--dark"
                }`}
              >
                <div className="project__title ">
                  <span className="project__type">React NPM Package</span>
                  <h4 className="project__name heading-2">React-Toastified</h4>
                </div>
                <div className="project__description">
                  <p>
                    React-Toastified is a deliciously styled notification system
                    for React apps. It offers a range of toast styles like
                    Success, Error, Warning, and Info, with customizable options
                    and a magical hover effect to enhance the user experience.
                  </p>
                </div>
                <div className="project__tech">
                  <div className="scroll-container">
                    <pre className="scroll-text">
                      &#183; React &#183; Javascript &#183; Custom Styles &#183;
                      NPM
                    </pre>
                  </div>
                </div>
                <div className="npm-project__links">
                  Total downloads: {toastDownloadTotal}{" "}
                  {/* Assuming totalCount is a variable storing the total download count */}
                </div>
                <div className="project__links ">
                  <a target="_blank" href="https://www.react-toastified.com">
                    <button className="custom-btn btn-12">
                      <span>Click!</span>
                      <span>View Demo </span>
                    </button>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.npmjs.com/package/react-toastified"
                  >
                    <button className="custom-btn btn-12">
                      <span>Click!</span>
                      <span>View NPM Package </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className="npm-project">
              <div
                className={` ${
                  colorMode
                    ? "npm-project__content"
                    : "npm-project__content--dark"
                }`}
              >
                <div className="project__title">
                  <span className="project__type ">React NPM Package</span>
                  <h4 className="project__name heading-2">
                    React Github Activity Calendar
                  </h4>
                </div>
                <div className="project__description">
                  <p>
                    React Github Activity Calendar is a React component that
                    displays a user's Github activity in a calendar format. It's
                    a great way to showcase your Github contributions and keep
                    track of your development progress over time. As a web
                    developer, I built and maintain this package as a tool for
                    other developers to use in their own projects.
                  </p>
                </div>
                <div className="project__tech">
                  <div className="scroll-container">
                    <pre className="scroll-text">
                      &#183; Github API &#183; Graphql &#183; React &#183;
                      Javascript &#183; Babel&#183;SASS&#183; NPM
                    </pre>
                  </div>
                </div>
                <div className="npm-project__links">
                  Total downloads: {totalCount}
                </div>
                <div className="project__links">
                  <a
                    target="_blank"
                    href="https://www.npmjs.com/package/react-github-activity-calendar"
                  >
                    <button className="custom-btn btn-12">
                      <span>Click!</span>
                      <span>View Live</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="npm-project npm-project__github">
              <div className="npmpackage-section ">
                {/* <div className="npmpackage-section__select-or-slide">
                  <div className="npmpackage-section__select-or-slide__left">
                    <h1 className="box-shadow">Select</h1>
                    <div className="color-options npm-color-custom">
                      <div className="color-option-group">
                        <span className="color-option-label">
                          Background Color:
                        </span>
                        {bgOptions.map((color) => (
                          <label key={color.value}>
                            <input
                              type="radio"
                              value={color.value}
                              checked={bgColorValue === color.value}
                              onChange={handleBgColorChange}
                            />
                            <span
                              className="color-circle"
                              style={{
                                // background: `hsl(${color.value}, 100%, 75%)`,
                                background: `${color.value}`,
                              }}
                            ></span>
                          </label>
                        ))}
                      </div>
                      <div className="color-option-group">
                        <span className="color-option-label">Text Color:</span>
                        {textOptions.map((color) => (
                          <label key={color.value}>
                            <input
                              type="radio"
                              value={color.value}
                              checked={textColorValue === color.value}
                              onChange={handleTextColorChange}
                            />
                            <span
                              className="color-circle"
                              style={{
                                // background: `hsl(${color.value}, 100%, 60%)`,
                                background: `${color.value}`,
                              }}
                            ></span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="npmpackage-section__select-or-slide__mid">
                    <div className="npmpackage-section__select-or-slide__mid--top"></div>
                    <h1 className="box-shadow">or</h1>
                    <div className="npmpackage-section__select-or-slide__mid--bottom"></div>
                  </div>
                  <div className="npmpackage-section__select-or-slide__right">
                    <h1 className="box-shadow">Slide</h1>{" "}
                    <div>
                      <div className="npm-color-custom">
                        <div className="color-slider npm-color-custom__bg">
                          <label>Background Color:</label>
                          <input
                            type="range"
                            min="0"
                            max="360"
                            value={bgColorValue}
                            onChange={handleBgColorChange}
                          />
                        </div>
                        <div className="color-slider npm-color-custom__tc">
                          <label>Text Color:</label>
                          <input
                            type="range"
                            min="0"
                            max="360"
                            value={textColorValue}
                            onChange={handleTextColorChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="npmpackage-section__select-or-slide">
                  {/* <div className="npmpackage-section__select-or-slide__left"> */}
                  <h1 className="box-shadow">customizable colors</h1>
                  <div className="color-options npm-color-custom">
                    <div className="color-option-group">
                      <span className="color-option-label">
                        Background Color:
                      </span>
                      {bgOptions.map((color) => (
                        <label key={color.value}>
                          <input
                            type="radio"
                            value={color.value}
                            checked={bgColorValue === color.value}
                            onChange={handleBgColorChange}
                          />
                          <span
                            className="color-circle"
                            style={{
                              background: `${color.value}`,
                            }}
                          ></span>
                        </label>
                      ))}
                      {/* </div> */}
                      <div className="color-option-group">
                        <span className="color-option-label">Text Color:</span>
                        {textOptions.map((color) => (
                          <label key={color.value}>
                            <input
                              type="radio"
                              value={color.value}
                              checked={textColorValue === color.value}
                              onChange={handleTextColorChange}
                            />
                            <span
                              className="color-circle"
                              style={{
                                background: `${color.value}`,
                              }}
                            ></span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* <div className="npmpackage-section__select-or-slide__mid">
                    <div className="npmpackage-section__select-or-slide__mid--top"></div>
                    <h1 className="box-shadow">or</h1>
                    <div className="npmpackage-section__select-or-slide__mid--bottom"></div>
                  </div>
                  <div className="npmpackage-section__select-or-slide__right">
                    <h1 className="box-shadow">Slide</h1>
                    <div className="npm-color-custom">
                      <div className="color-slider npm-color-custom__bg">
                        <label>Background Color:</label>
                        <input
                          type="range"
                          min="0"
                          max="360"
                          value={bgColorValue}
                          onChange={handleBgColorChange}
                        />
                      </div>
                      <div className="color-slider npm-color-custom__tc">
                        <label>Text Color:</label>
                        <input
                          type="range"
                          min="0"
                          max="360"
                          value={textColorValue}
                          onChange={handleTextColorChange}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>

                {/* <div className="npm-color-custom"></div> */}

                <Graph
                  backgroundColor={bgColor}
                  color={textColor}
                  githubApiKey={githubApiKey}
                  userName={githubUserName}
                />
              </div>
            </div>
            <div className="project">
              <div className="project__group">
                <ProjectTrio currentColor={colorMode} />
              </div>
              <div
                className={` ${
                  colorMode ? "project__content" : "project__content--dark"
                }`}
              >
                <div className="project__title">
                  <span className="project__type ">
                    Course Content &#183; 3 Projects
                  </span>
                  <h4 className="project__name heading-2">
                    Advanced CSS and Sass
                  </h4>
                </div>
                <div className="project__description">
                  <p>
                    In this course, I created 3 responsive projects using
                    distinct CSS layouts - float, flexbox, and Grid. Through
                    this course, I gained proficiency in modern CSS techniques
                    to design modern layouts and effects.
                  </p>
                </div>
                <div className="project__tech">
                  <div className="scroll-container">
                    <pre className="scroll-text">
                      Flexbox &#183; CSS Grid &#183; HTML &#183; NPM &#183; Sass
                      &#183;Scss &#183; CSS
                    </pre>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-reverse">
              <div
                className={` ${
                  colorMode
                    ? "project-reverse__content"
                    : "project-reverse__content--dark"
                }`}
              >
                <div className="project__title">
                  <span className="project__type ">
                    Freelance &#183; Website
                  </span>
                  <h4 className="project__name heading-2">
                    Saiz Income tax service
                  </h4>
                </div>
                <div className="project__description">
                  <p>
                    Created website for tax industry business without online
                    presence, increasing visibility and attracting new clients.
                  </p>
                </div>
                <div className="project__tech">
                  <div className="scroll-container">
                    <pre className="scroll-text">
                      React &#183; Javascript &#183;
                      Contact-Form&#183;EmailJS&#183; CICD &#183; Docker &#183;
                      Nginx &#183; Google Maps
                    </pre>
                  </div>
                </div>
                <div className="project__links">
                  <a target="_blank" href="https://saizincometax.com/">
                    <button className="custom-btn btn-12">
                      <span>Click!</span>
                      <span>View Live</span>
                    </button>
                  </a>
                </div>
              </div>
              <div
                className="project-reverse__picture"
                // data-lag="0.1"
                // data-speed="1"
              >
                <img
                  src={saiz}
                  loading="lazy"
                  alt="website saiz income tax"
                  className="project-reverse__img"
                  data-speed="auto"
                />
              </div>
            </div>
            <div className="project">
              <div className="project__picture" data-lag="0.1" data-speed="1">
                {" "}
                <img
                  loading="lazy"
                  src={pokemonBinder}
                  alt="Online Pokemon Binder website"
                  className="project__img"
                  data-speed="auto"
                />
              </div>
              <div
                className={` ${
                  colorMode ? "project__content" : "project__content--dark"
                }`}
              >
                <div className="project__title">
                  <span className="project__type ">
                    {" "}
                    Personal Project &#183; Full Stack
                  </span>
                  <h4 className="project__name heading-2">Pokemon Binder</h4>
                </div>
                <div className="project__description">
                  <p>
                    This web application allows users to keep track of their
                    cards or look up a card to add to their deck!
                  </p>
                </div>
                <div className="project__tech">
                  <div className="scroll-container">
                    <pre className="scroll-text">
                      React &#183; Javascript &#183; Python &#183; Flask &#183;
                      Auth0 &#183; CICD &#183; Pokemon TCG API &#183; SQLAlchemy
                      &#183; PostgreSQL &#183; Flask-Migrate &#183; Docker
                      &#183; CRUD operations &#183; Nginx
                    </pre>
                  </div>
                </div>
                <div className="project__links">
                  <a target="_blank" href="https://pokemonbinder.net/">
                    <button className="custom-btn btn-12">
                      <span>Click!</span>
                      <span>View Live</span>
                    </button>
                  </a>
                  {/* <a
                  href="https://pokemonbinder.net/"
                  target="_blank"
                  className="btn-text"
                >
                  View Live &rArr;
                </a> */}
                </div>
              </div>
            </div>
            <div className="project-reverse">
              <div
                className={` ${
                  colorMode
                    ? "project-reverse__content"
                    : "project-reverse__content--dark"
                }`}
              >
                <div className="project__title">
                  <span className="project__type ">
                    Personal Project &#183; NFT &#183; Website
                  </span>
                  <h4 className="project__name heading-2">NFT collection</h4>
                </div>
                <div className="project__description">
                  <p>
                    I participated in a 3-day NFT Hackathon where I created a
                    unique NFT collection using HashLip's generative art
                    repository. I gained experience deploying a smart contract
                    and uploading NFTs to OpenSea, as well as building a website
                    in React to showcase the collection.
                  </p>
                </div>
                <div className="project__tech">
                  <div className="scroll-container">
                    <pre className="scroll-text">
                      React &#183; Javascript &#183; Python &#183; CICD &#183;
                      Docker &#183; Nginx &#183; Selenium
                    </pre>
                  </div>
                </div>
                <div className="project__links">
                  <a target="_blank" href="https://likey.charo.gg/">
                    <button className="custom-btn btn-12">
                      <span>Click!</span>
                      <span>View Live</span>
                    </button>
                  </a>
                </div>
              </div>
              <div
                className="project-reverse__picture"
                // data-lag="0.1"
                // data-speed="1"
              >
                {" "}
                <img
                  loading="lazy"
                  src={likey}
                  alt="NFT collection website screenshot"
                  className="project-reverse__img"
                  data-speed="auto"
                />
              </div>
            </div>

            <div className="project">
              <div className="project__picture">
                <img
                  loading="lazy"
                  src={fyyur}
                  alt="Fyyur app website screenshot"
                  className="project__img"
                  data-speed="auto"
                />
              </div>
              <div
                className={` ${
                  colorMode ? "project__content" : "project__content--dark"
                }`}
              >
                <div className="project__title">
                  <span className="project__type ">
                    Course Content &#183; Full Stack
                  </span>
                  <h4 className="project__name heading-2">Fyyur App</h4>
                </div>
                <div className="project__description">
                  <p>
                    Fyyur is a platform that allows users to create listings,
                    discover new artists and venues, and book shows.
                  </p>
                </div>
                <div className="project__tech">
                  <div className="scroll-container">
                    <pre className="scroll-text">
                      Docker &#183; Python &#183; Flask&#183; SQLAlchemy&#183;
                      PostgreSQL&#183; Flask-Migrate&#183; Bootstrap 3&#183;
                      Docker &#183; Nginx
                    </pre>
                  </div>
                </div>
                <div className="project__links">
                  <a target="_blank" href="https://fyyur.charo.gg/">
                    <button className="custom-btn btn-12">
                      <span>Click!</span>
                      <span>View Live</span>
                    </button>
                  </a>
                  {/* <a
                  href="https://fyyur.charo.gg/"
                  className="btn-text"
                  target="_blank"
                >
                  View Live &rArr;
                </a> */}
                </div>
              </div>
            </div>
            <div className="project-reverse">
              <div
                className={` ${
                  colorMode
                    ? "project-reverse__content"
                    : "project-reverse__content--dark"
                }`}
              >
                <div className="project__title">
                  <span className="project__type ">
                    Course Content &#183; Full Stack
                  </span>
                  <h4 className="project__name heading-2">Trivia App</h4>
                </div>
                <div className="project__description">
                  <p>
                    This project is an interactive trivia quiz platform that
                    allows users to create, edit, and play quizzes with
                    customizable features such as question filters, search
                    functionality, and randomized quizzes.
                  </p>
                </div>
                <div className="project__tech">
                  <div className="scroll-container">
                    <pre className="scroll-text">
                      Docker &#183; Python &#183; Flask&#183; SQLAlchemy&#183;
                      PostgreSQL&#183; Docker &#183; Nginx
                    </pre>
                  </div>
                </div>
                <div className="project__links">
                  <a target="_blank" href="https://trivia.charo.gg/">
                    <button className="custom-btn btn-12">
                      <span>Click!</span>
                      <span>View Live</span>
                    </button>
                  </a>
                  {/* <a
                  href="https://trivia.charo.gg/"
                  className="btn-text"
                  target="_blank"
                >
                  View Live &rArr;
                </a> */}
                </div>
              </div>
              <div
                className="project-reverse__picture"
                // data-lag="0.1"
                // data-speed="1"
              >
                {" "}
                <img
                  loading="lazy"
                  src={trivia}
                  alt="trivia  website screenshot"
                  className="project-reverse__img"
                  data-speed="auto"
                />
                {/* <img
                  src={trivia}
                  alt="trivia  website screenshot"
                  className="project-reverse__img"
                /> */}
              </div>
            </div>
          </div>
          <div className="degrees" id="degrees">
            {/* <div>
              <div
                ref={boxRef}
                style={{
                  width: "100px",
                  height: "100px",
                  backgroundColor: "red",
                }}
              ></div>
              <button onClick={handleAnimate}>Animate Box</button>
            </div> */}
            {/* <AlbumComponent /> */}
            {/* <HoverComponent /> */}
            <Knowledge />
          </div>
          <footer className="footer">
            <ul className="nav">
              <li className="nav__item">
                <a href="#" className="nav__link">
                  Home
                </a>
              </li>
              <li className="nav__item">
                <a href="#about-me" className="nav__link" onClick={aboutscroll}>
                  About me
                </a>
              </li>
              <li className="nav__item">
                <a href="#projects" className="nav__link">
                  Projects
                </a>
              </li>

              <li className="nav__item">
                <a
                  href="https://www.linkedin.com/in/justincharo/"
                  className="nav__link"
                  target="_blank"
                >
                  contact me
                </a>
              </li>
            </ul>
            <p className="copyright">
              &copy; 2023 Developed & Designed by J.Charo
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default App;
