import React from "react";
import ReactDOM from "react-dom/client";
import "./sass/_main.scss";
import App from "./App";
import { ToastProvider } from "react-toastified";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ToastProvider>
      <App />
    </ToastProvider>
  </React.StrictMode>
);
