import React from 'react';

const ResumeComponent = ({ colorMode }) => {
    return (
        <div className={`resume-container ${colorMode ? "" : ""}`} data-speed="auto">
            <div className="position-section " >
                <h3 className="text-lg"  >April 2023 — PRESENT</h3>
                <h2 className="title">Full Stack Software Developer
                    • Digital Publishing Inc </h2>
                <ul>
                    {/* <li>Senior Engineer</li> */}

                </ul>
                <p className="description">
                    Developing and managing 3 Full Stack web application backend APIs, ensuring robust and seamless backend services. My significant contributions include streamlining development workflows through the establishment of continuous integration and deployment pipelines, reducing payout fees  with the integration of PayPal Checkout and an automated payout system, and playing a key role in the development of a comprehensive SaaS e-learning platform. </p>                <div className="skills">
                    <span>React</span>
                    <span>NextJs</span>
                    <span>SCSS</span>
                    <span>Java Spring Boot</span>
                    <span>JavaScript</span>
                    <span>AWS</span>
                    <span>tailwind</span>
                    <span>Stripe</span>
                    <span>PayPal</span>
                </div>
            </div>
            {/* ... Add other positions as separate "position-section" divs */}
        </div>
    );
}

export default ResumeComponent;
