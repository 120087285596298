// import React, { useState, useRef, useEffect } from "react";
// import udemy from "./img/udemy.jpeg";
// import udemyWebp from "./img/udemy.webp";
// import dsaDegree from "./img/DSA.png";
// import dsaDegreeWebp from "./img/DSA.webp";
// import fullStack from "./img/full_stack1.png";
// import fullStackWebp from "./img/full_stack.webp";
// import introProgramming from "./img/intro_program1.png";
// import introProgrammingWebp from "./img/intro_program.webp";
// import { gsap, Power3 } from "gsap";

// function Knowledge() {
//   const [selected, setSelected] = useState(0);
//   const [hoveredImage, setHoveredImage] = useState(null);
//   const imageDiv = useRef(null);

//   const items = [
//     {
//       id: 4,
//       name: "Advanced CSS and Sass",
//       picture: udemy,
//       pictureWebp: udemyWebp,
//     },
//     {
//       id: 1,
//       name: "Data Structures & Algorithms",
//       picture: dsaDegree,
//       pictureWebp: dsaDegreeWebp,
//     },
//     {
//       id: 2,
//       name: "Full Stack Web Developer",
//       picture: fullStack,
//       pictureWebp: fullStackWebp,
//     },
//     {
//       id: 3,
//       name: "Intro to Programing",
//       picture: introProgramming,
//       pictureWebp: introProgrammingWebp,
//     },
//   ];

//   useEffect(() => {
//     if (!imageDiv.current) return;

//     const onMouseMove = (e) => {
//       console.log(e.clientX);
//       // gsap.to(imageDiv.current, {
//       //   x: e.clientX / 350, // removed the offset to place the image right under the cursor
//       //   y: e.clientY,
//       //   duration: 1,
//       //   ease: "ease",
//       // });
//     };

//     // const onMouseEnter = (imageSrc) => () => {
//     //   setHoveredImage(imageSrc);
//     //   gsap.set(imageDiv.current, { opacity: 1 });
//     //   window.addEventListener("mousemove", onMouseMove);
//     // };

//     // const onMouseLeave = () => {
//     //   setHoveredImage(null);
//     //   gsap.set(imageDiv.current, { opacity: 0 });
//     //   window.removeEventListener("mousemove", onMouseMove);
//     // };

//     import { useRef, useEffect, useState } from 'react';

//     const YourComponent = () => {
//       const [hoveredImage, setHoveredImage] = useState(null);
//       const imageDiv = useRef(null);

//       const onMouseMove = (e) => {
//         console.log(e.clientX);
//         // gsap animation or whatever you need
//       };

//       const onMouseEnter = (imageSrc) => () => {
//         setHoveredImage(imageSrc);
//         gsap.to(imageDiv.current, {
//           opacity: 1,
//           duration: 0.8,
//           ease: Power3.easeIn,
//         });
//         window.addEventListener("mousemove", onMouseMove);
//       };

//       const onMouseLeave = () => {
//         setHoveredImage(null);
//         gsap.to(imageDiv.current, {
//           opacity: 0,
//           duration: 0.8,
//           ease: Power3.easeOut,
//         });
//         window.removeEventListener("mousemove", onMouseMove);
//       };

//       useEffect(() => {
//         return () => {
//           window.removeEventListener("mousemove", onMouseMove); // Cleanup
//         };
//       }, []);

//   return (
//     <div style={{ position: "relative" }}>
//       {items.map((item) => {
//         return (
//           <div
//             key={item.id}
//                 key={item.id}
//           className={`hover-item-${item.id}`}
//           onMouseEnter={onMouseEnter(item.picture)}
//           onMouseLeave={onMouseLeave}
//             className={`degrees__item hover-item-${item.id} ${
//               selected === item.id ? "degrees__focused" : ""
//             }`}
//             onClick={() => setSelected(item.id)}
//           >
//             <span>{item.name}</span>
//           </div>
//         );
//       })}

//       <img
//         ref={imageDiv}
//         style={{
//           position: "absolute",
//           top: "0% ",
//           right: "0",
//           opacity: 0,
//           // pointerEvents: "none",
//           borderRadius: "1rem",
//         }} // Added pointerEvents to ensure the image doesn't interfere with any interactions
//         src={hoveredImage}
//         loading="lazy"
//         alt="Hovered Degree"
//       />
//     </div>
//   );
// }

// export default Knowledge;

import React, { useState, useRef, useEffect } from "react";
import udemy from "./img/udemy.jpeg";
import udemyWebp from "./img/udemy.webp";
import dsaDegree from "./img/DSA.png";
import dsaDegreeWebp from "./img/DSA.webp";
import fullStack from "./img/full_stack1.png";
import fullStackWebp from "./img/full_stack.webp";
import introProgramming from "./img/intro_program1.png";
import introProgrammingWebp from "./img/intro_program.webp";
import { gsap, Power3 } from "gsap";

function Knowledge() {
  const [selected, setSelected] = useState(0);
  const [hoveredImage, setHoveredImage] = useState(null);
  const imageDiv = useRef(null);

  const items = [
    {
      id: 4,
      name: "Advanced CSS and Sass",
      course: "Udemy'23",
      picture: udemy,
      pictureWebp: udemyWebp,
    },
    {
      id: 1,
      name: "Data Structures & Algorithms",
      course: "UDACITY'22",
      picture: dsaDegree,
      pictureWebp: dsaDegreeWebp,
    },
    {
      id: 2,
      name: "Full Stack Web Developer",
      course: "UDACITY'21",
      picture: fullStack,
      pictureWebp: fullStackWebp,
    },
    {
      id: 3,
      name: "Intro to Programming",
      course: "UDACITY'20",
      picture: introProgramming,
      pictureWebp: introProgrammingWebp,
    },
  ];
  const screenWidth = window.innerWidth;

  const onMouseMove = (e) => {
    // gsap.to(imageDiv.current, {
    //   x: e.clientX / 350, // removed the offset to place the image right under the cursor
    //   y: e.clientY,
    //   duration: 1,
    //   ease: "ease",
    // });
  };

  // const onMouseEnter = (imageSrc) => () => {
  //   setHoveredImage(imageSrc);
  //   gsap.set(imageDiv.current, { opacity: 1 });
  //   window.addEventListener("mousemove", onMouseMove);
  // };

  // const onMouseLeave = () => {
  //   setHoveredImage(null);
  //   gsap.set(imageDiv.current, { opacity: 0 });
  //   window.removeEventListener("mousemove", onMouseMove);
  // };

  const onMouseEnter = (imageSrc) => () => {
    setHoveredImage(imageSrc);
    gsap.to(imageDiv.current, {
      opacity: 1,
      duration: 0.8,
      ease: Power3.easeIn,
    });
    window.addEventListener("mousemove", onMouseMove);
  };

  const onMouseLeave = () => {
    setHoveredImage(null);
    gsap.to(imageDiv.current, {
      opacity: 0,
      duration: 0.8,
      ease: Power3.easeOut,
    });
    window.removeEventListener("mousemove", onMouseMove);
  };

  useEffect(() => {
    return () => {
      window.removeEventListener("mousemove", onMouseMove); // Cleanup
    };
  }, []);
  useEffect(() => {
    console.log(hoveredImage);
  }, [hoveredImage]);
  return (
    <div style={{ position: "relative" }}>
      {items.map((item) => {
        return (
          <div
            key={item.id}
            className={`degrees__item hover-item-${item.id}`}
            onMouseEnter={onMouseEnter(item.picture)}
            onMouseLeave={onMouseLeave}
            onClick={() => setSelected(item.id)}
          >
            <span>{item.name}</span>{" "}
            {screenWidth >= 700 ? (
              <span style={{ textAlign: "right" }}>{item.course}</span>
            ) : null}
          </div>
        );
      })}

      <img
        ref={imageDiv}
        style={{
          position: "absolute",
          top: "0% ",
          right: "0",
          opacity: 0,
          width: `${hoveredImage?.includes("DSA") ? "50%" : "75%"}`,
          pointerEvents: "none",
          borderRadius: "1rem",
        }} // Added pointerEvents to ensure the image doesn't interfere with any interactions
        src={hoveredImage}
        loading="lazy"
        alt="Hovered Degree"
      />
    </div>
  );
}

export default Knowledge;
