import React, { useEffect } from "react";
// import nextar from "./img/nextar-2x.png";
// import nextarWebp from "./img/nextar-2x.webp";
// import nartour from "./img/nartour-2x.png";
// import nartourWebp from "./img/nartour-2x.webp";
// import trillo from "./img/trillo-2x.jpeg";
// import trilloWebp from "./img/trillo-2x.webp";

function ProjectTrio(props) {
  useEffect(() => {
    const prevBtn = document.querySelector(".prev");
    const nextBtn = document.querySelector(".next");
    const cards = document.querySelector(".cards");
    let currentCard = 0;

    nextBtn.addEventListener("click", () => {
      currentCard++;
      if (currentCard > 2) {
        currentCard = 0;
      }
      cards.style.transform = `translateX(-${currentCard * 33.33}%)`;
    });

    prevBtn.addEventListener("click", () => {
      currentCard--;
      if (currentCard < 0) {
        currentCard = 2;
      }
      cards.style.transform = `translateX(-${currentCard * 33.33}%)`;
    });
  }, []);

  return (
    <>
      <div className="carousel">
        <div className="cards">
          <div className="card">
            <iframe
              //   height="500"
              style={{ width: "100%", height: "500px" }}
              scrolling="no"
              title="Flexbox project"
              src="https://codepen.io/jcharo1/embed/qBMOdPJ?default-tab=html%2Cresult-box"
              frameBorder="no"
              //   loading="lazy"
            >
              See the Pen{" "}
              <a href="https://codepen.io/jcharo1/pen/qBMOdPJ">
                Flexbox project
              </a>{" "}
              by justin (<a href="https://codepen.io/jcharo1">@jcharo1</a>) on{" "}
              <a href="https://codepen.io">CodePen</a>.
            </iframe>
          </div>
          <div className="card">
            <iframe
              //   height="500"
              style={{ width: "100%", height: "500px" }}
              scrolling="no"
              title="css grid project"
              src="https://codepen.io/jcharo1/embed/ExeVjXE?default-tab=html%2Cresult-box"
              frameBorder="no"
              //   loading="lazy"
              //   allowtransparency="true"
              //   allowfullscreen="true"
            >
              See the Pen{" "}
              <a href="https://codepen.io/jcharo1/pen/ExeVjXE">
                css grid project
              </a>{" "}
              by justin (<a href="https://codepen.io/jcharo1">@jcharo1</a>) on{" "}
              <a href="https://codepen.io">CodePen</a>.
            </iframe>
          </div>
          <div className="card">
            <iframe
              //   height="500"
              style={{ width: "100%", height: "500px" }}
              scrolling="no"
              title="Float Layout"
              src="https://codepen.io/jcharo1/embed/yLxReZm?default-tab=html%2Cresult-box"
              frameBorder="no"
              //   loading="lazy"
              //   allowTransparency="true"
              //   allowFullscreen="true"
            >
              See the Pen{" "}
              <a href="https://codepen.io/jcharo1/pen/yLxReZm">Untitled</a> by
              justin (<a href="https://codepen.io/jcharo1">@jcharo1</a>) on{" "}
              <a href="https://codepen.io">CodePen</a>.
            </iframe>
          </div>
        </div>
        <button
          className="prev"
          style={{
            color: `${props?.currentColor ? "#201e1e" : "rgb(255, 246, 239"}`,
          }}
        >
          &#10094;
        </button>
        <button
          className="next"
          style={{
            color: `${props?.currentColor ? "#201e1e" : "rgb(255, 246, 239"}`,
          }}
        >
          &#10095;
        </button>
      </div>
    </>
  );
}
export default ProjectTrio;
